.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    color: white;
    padding: 20px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10%;
  }
.steps{
    width: 100%;
    background: linear-gradient(90deg, #7F00FF 34.18%, #FFFFFF 112.71%),
                linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 20px;
}
  .column {
    flex: 0 0 calc(33.33% - 20px);
    margin: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  .column h2{
    font-size: 22px;
  }
  .column p{
    font-size: 18px;
  }
  .nopd-2{
    padding: 20px !important;
    margin-top: 30px;
  }
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .column {
      flex: 0 0 calc(100% - 20px); 
    }
    .sub-title {
      font-size: 26px;
      padding: 20px;
    }
    .nopd-2{
      padding: 20px !important;
      margin-top: 0px;
    }

  }
  