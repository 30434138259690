
  .navbar{
padding: 2% 5%;
position: relative;
z-index: 999999999;
  }
  .navbar-logo {
    font-size: 20px;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    padding: 0 6px;

  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #333;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: white;
    padding: 12px 6px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-link {
    position: relative;
  }
  .navbar-links {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .navbar-links a,
  .dropdown-link {
    color: white;
    text-decoration: none;
    padding: 12px 16px;
    display: inline-block;
    position: relative;
  }
  .navbar-toggle {
    display: none;
  }
.get-started-text{
    display: block;
}
  .arrow {
    position: absolute;
    top: 55%;
    padding-right: 2px;
    left: 90%;
    transform: translateY(-50%);
  }
  .get-started{
    display: none;
  }
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #333;
      width: 100%;
    }
  
    .navbar-links.show {
      display:inline-flex;
    }
    .get-started-text{
        display: none;
    }
    .get-started{
        display: block;
      }
    .navbar-toggle {
      display: block;
      font-size: 20px;
      cursor: pointer;
    }
  }
  